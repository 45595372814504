
import CustomButton from "@/components/CustomButton.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { CustomButton },
  props: {
    identity: {
      type: String,
      required: true,
    },
    data: {
      type: Object as PropType<{
        url: string;
      }>,
      required: true,
    },
  },
  computed: {
    url(): any {
      if (!this.data.url.startsWith("http")) {
        return "https://" + this.data.url;
      }
      return this.data.url;
    },
  },
  methods: {
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    pushRouter(link: string) {
      const match = process.env.VUE_APP_MAIN_APP_URL + "app";
      if (link.startsWith(match)) {
        this.$router.push(
          "/" + link.split(process.env.VUE_APP_MAIN_APP_URL as string)[1]
        );
        return true;
      }
      return false;
    },
    visitLink() {
      if (this.pushRouter(this.url)) {
        this.close();
        return;
      }
      const win = window.open(this.url, "_blank");
      win?.focus?.();
      this.close();
    },
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
  },
});
